.arrow-right-icon {
  font-size: 30px;
  align-items: center;
  color: #00703c;
}

.govuk-table__cell {
  vertical-align: middle;
}

th {
  cursor: pointer;
}

.govuk-main-wrapper {
  display: block;
}
