.home-page{
    margin-bottom: 20px;
}
.home-page-banner{
    background:#d4351c;
    border-color: #d4351c;
    padding: 5px;
    margin-bottom: 40px;
    font-family: "GDS Transport", arial, sans-serif;
    font-size: 1.1875rem;
    line-height: 1.3157894737;
    border-radius: 1px;

}