.pagination .disabled-link {
    pointer-events: none;
    color: gray;
    display: block;
    text-decoration: none;
    cursor: default;
    border: none;
    pointer-events: none;
}

.pagination .disabled-link :focus {
    outline: 0;
}

.pagination .disabled-link :hover, .pagination .disabled-link :focus {
    background: none;
    outline: 3px solid yellow;
}

.pagination li {
    display: inline-block;
}
.pagination li a {
    color: #005ea5;
    display: inline-block;
    padding: 15px 5px 10px 5px;
    margin-right: 15px;
    text-decoration: none;
}
.pagination li a:focus {
    outline: 0;
}
.pagination li.active a, .pagination li.active a:hover {
    color: #005ea5;
    -webkit-box-shadow: inset 0px -5px 0px 0px #005ea5;
    -moz-box-shadow: inset 0px -5px 0px 0px #005ea5;
    box-shadow: inset 0px -5px 0px 0px #005ea5;
}
.padding {
    padding-left: 15px;
}
.pagination {
    padding: 0;
}
.pagination__item {
    display: inline-block;
    list-style: none;
}
.pagination__link {
    display: block;
    padding: 5px 10px;
    text-decoration: none;
}
.pagination__link:hover, .pagination__link:focus {
    background: #f8f8f8;
    outline: 3px solid #ffbf47;
}
.pagination__link.current {
    color: #0b0c0c;
    font-weight: 700;
    border: none;
    pointer-events: none;
    cursor: default;
}
.pagination__link.current:hover, .pagination__link.current:focus {
    color: #0b0c0c;
    background: none;
}
.pagination__summary {
    font-family: 'Inter UI', sans-serif;
    -webkit-font-smoothing: antialiased;
    padding: 8px 0;
}
@media (min-width: 642px) {
    .pagination__summary {
        float: right;
   }
}
