.header-text {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.sign-out {
    margin-top: 5px;
}

.sign-out a {
    font-weight: 600;
}

#skiplink-container:focus {
    outline: none;
}

.govuk-visually-hidden-focusable:focus {
    position: absolute !important;
}
