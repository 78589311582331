.gov-uk-crest {
  background-image: url('./assets/gov/govuk-crest.png');
}

.govuk-header__logotype-text {
  margin-left: 6px;
}

.gov-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.gov-content {
  flex: 1;
}

.govuk-width-container {
  width: 90%;
}

@media (max-width: 700px) {
  .govuk-width-container {
    width: 90%;
  }
}

@media (max-width: 420px) {
  .govuk-width-container {
    width: 93%;
  }
}

@media (max-width: 320px) {
  .govuk-width-container {
    width: 90%;
  }
}

.govuk-main-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.govuk-main-wrapper:focus {
  outline: none;
}

.link {
  color: #1d70b8;
  text-decoration-line: underline;
}

.link:hover {
  cursor: pointer;
}

.address {
  border-left: 1px solid #b1b4b6;
  padding-left: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
}